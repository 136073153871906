import { Box, VStack } from '@chakra-ui/react'

export interface FloatingButtonProps {
  buttons: React.ReactNode
}

export default function FloatingButtons({ buttons }: FloatingButtonProps) {
  return (
    <Box
      position="fixed"
      bottom="16px"
      right="16px"
      zIndex="9999"
      display={{ base: 'flex', md: 'none' }}
    >
      <VStack spacing={2}>{buttons}</VStack>
    </Box>
  )
}
