import { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Image,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react'

import DocumentsSubmitted from '~/assets/DocumentsSubmitted.svg'
import { routes } from '~/constants/routes'

import { usePublicSubmission, useSubmissionPublicId } from './public.hooks'

export const ConfirmationPage = (): JSX.Element => {
  const { submissionPublicId } = useSubmissionPublicId()
  const navigate = useNavigate()
  const { submission, isSubmissionLoading } =
    usePublicSubmission(submissionPublicId)

  // Navigate user back to top of page when page loads
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const submittedDocuments = useLocation().state?.fileNames

  if (isSubmissionLoading) return <Spinner />

  if (!submission) return <Navigate to={routes.public.error} />

  return (
    <Box width={{ base: '100%', md: '50%' }} height="50%" mb="50px">
      <VStack
        maxW={{ base: '100vw', md: '100%' }}
        align="center"
        spacing={10}
        p={{ base: 6, md: 10 }}
      >
        <Image src={DocumentsSubmitted} aria-hidden />
        <VStack w="full" align="start">
          <VStack align="start" w="full" spacing={4}>
            <Text textStyle="h4">Documents successfully submitted</Text>
            <Text textStyle="body-1">
              You have uploaded the following documents:
            </Text>
          </VStack>
          <Flex>
            {/* List out all the submitted documents as a list of bullet points */}
            <UnorderedList spacing={2}>
              {submittedDocuments?.map((fileName: string) => (
                <ListItem key={fileName}>
                  <Text textStyle="body-1">{fileName}</Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        </VStack>
        <Button
          variant="outline"
          alignSelf={{ base: 'stretch', md: 'center' }}
          w={{ base: 'unset', md: '100%' }}
          mb={20}
          onClick={() => navigate('../')}
        >
          Upload more
        </Button>
      </VStack>
    </Box>
  )
}
