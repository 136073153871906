import { useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Hide,
  HStack,
  IconButton,
  Image,
  Show,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Spinner } from '@opengovsg/design-system-react'

import DocumentsFlying from '~/assets/DocumentsFlying.svg'
import { SearchInput } from '~/components/SearchInput'
import { useAdminAuth } from '~/features/auth'
import { useGetCampaigns } from '~/features/dashboard/hooks/campaigns.hooks'

import { Campaigns, PAGE_SIZE } from './Campaigns/Campaigns'
import FloatingButtons from './components/FloatingButtons'
import { EmptyDashboard } from './EmptyState/EmptyDashboard'
import { AnnnouncementModal } from './AnnouncementModal'
import { BasicAdminPage } from './BasicAdminPage'

export const DashboardPageV2 = (): JSX.Element => {
  const navigate = useNavigate()

  const { adminUser } = useAdminAuth()

  const [searchQuery, setSearchQuery] = useState('')

  const { count: totalCount } = useGetCampaigns(PAGE_SIZE, 0, adminUser?.id)

  const { campaigns, isCampaignsLoading, count } = useGetCampaigns(
    PAGE_SIZE,
    0,
    adminUser?.id,
    searchQuery,
  )

  if (isCampaignsLoading) {
    return <Spinner />
  }

  if (!searchQuery.length && !campaigns?.length) {
    return <EmptyDashboard />
  }

  return (
    <BasicAdminPage
      title={
        searchQuery.length
          ? `Showing ${count ?? 0} of ${totalCount ?? 0} collections`
          : 'All collections'
      }
      buttons={
        <Hide below="md">
          <SearchInput onChange={setSearchQuery} searchValue={searchQuery} />
          <Button
            justifyContent="start"
            onClick={() => navigate('/admin/collections/create')}
            fontSize={'16px'}
          >
            <Hide below="md">
              <HStack spacing="10px">
                <BiPlus fontSize="20px" />
                <Text>Create collection</Text>
              </HStack>
            </Hide>
            <Show below="md">
              <BiPlus size={20} />
            </Show>
          </Button>
        </Hide>
      }
    >
      {searchQuery.length && count == 0 ? (
        <VStack spacing="20px" justify={'center'} height="70vh">
          <Image src={DocumentsFlying} mb="24px" />
          <VStack spacing="12px">
            <Text textStyle="h5" color="brand.primary.500">
              No results found
            </Text>
            <Text textStyle="h6" color="base.content.default">
              It seems we can’t find any results based on your search.
            </Text>
          </VStack>
        </VStack>
      ) : (
        <Campaigns searchQuery={searchQuery} />
      )}
      <AnnnouncementModal />
      <FloatingButtons
        buttons={
          <>
            <IconButton
              aria-label="create-collection"
              onClick={() => navigate('/admin/collections/create')}
              isRound
              size={'lg'}
              icon={<BiPlus size={28} />}
            />
          </>
        }
      />
    </BasicAdminPage>
  )
}
