import { BiFolder } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { Hide, HStack, Text, VStack } from '@chakra-ui/react'
import dayjs from 'dayjs'

import { GetCampaignDto } from '~shared/dtos'

import { Highlight } from '../components/Highlight'

const SPACING_BETWEEN_COLUMNS = '28px'

export const Campaign = ({
  campaign,
  query,
}: {
  campaign: GetCampaignDto
  query?: string
}) => {
  const {
    name,
    id,
    numberOfSubmissionLinks,
    lastUpdatedAt,
    numberOfFiles,
    contactEmail,
  } = campaign
  const lastUpdatedDateTime =
    dayjs(lastUpdatedAt)?.format('DD/MM/YYYY hh:mm a') ?? '--'
  const navigate = useNavigate()

  return (
    <HStack
      align="center"
      _hover={{
        bgColor: 'interaction.main-subtle.default',
        cursor: 'pointer',
      }}
      onClick={() => navigate(`/admin/collections/${id}`)}
      py={'24px'}
      px={{ base: '20px', md: '40px' }}
      textColor="base.content.strong"
      spacing={SPACING_BETWEEN_COLUMNS}
    >
      <HStack flex={3} spacing="12px">
        <BiFolder size="2rem" />
        <VStack align={'left'} width="100%" spacing="2px">
          <Text
            textStyle="body-1"
            fontSize={{ base: '16px' }}
            noOfLines={[1, 2, 3]}
            wordBreak="break-word"
          >
            <Highlight text={name} query={query ?? ''} />
          </Text>
          <Hide above="md">
            <Text
              text-style="caption-2"
              fontSize={'12px'}
              textColor={'interaction.sub.default'}
            >
              Modified {lastUpdatedDateTime}
            </Text>
          </Hide>
        </VStack>
      </HStack>
      <Hide below="md">
        <Text textStyle="body-1" flex={1}>
          {numberOfSubmissionLinks}
        </Text>
        <Text textStyle="body-1" flex={1}>
          {numberOfFiles}
        </Text>
        <Text flex={2} whiteSpace="normal" wordBreak="break-word">
          {contactEmail ?? 'NA'}
        </Text>
        <Text flex={2}>{lastUpdatedDateTime}</Text>
      </Hide>
    </HStack>
  )
}

export const CampaignHeader = () => {
  return (
    <Hide below="md">
      <HStack
        fontWeight="500"
        w="full"
        py="22px"
        px={{ base: '20px', md: '40px' }}
        textColor={'interaction.sub.default'}
        spacing={SPACING_BETWEEN_COLUMNS}
      >
        <Text flex={3} textStyle={'subhead-2'}>
          Collection Name
        </Text>

        <Text flex={1} textStyle={'subhead-2'}>{`No. of links`}</Text>
        <Text flex={1} textStyle={'subhead-2'}>{`No. of files`}</Text>
        <Text flex={2} textStyle={'subhead-2'}>
          Created by
        </Text>
        <Text flex={2} textStyle={'subhead-2'}>
          Last Updated
        </Text>
      </HStack>
    </Hide>
  )
}
