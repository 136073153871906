import { useCallback, useState } from 'react'
import { BiPlus, BiUserPlus } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  ButtonGroup,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react'

import { GetSubmissionDto } from '~shared/dtos'

import { AdminNotFoundComponent } from '~/components/NotFoundComponent/AdminNotFoundComponent'

import { useAdminAuth } from '../auth'
import { BasicAdminPage } from '../dashboard/BasicAdminPage'
import { ManageCollaboratorAccessModal } from '../dashboard/components/modals/ManageCollaboratorAccessModal'
import { EmptyCollection } from '../dashboard/EmptyState/EmptyCollection'
import { useCampaign } from '../dashboard/hooks/campaigns.hooks'
import { useGetSubmissions } from '../dashboard/hooks/submissions.hooks'
import { Submissions } from '../dashboard/Submissions'
import { CreateSubmissionModal } from '../dashboard/Submissions/CreateSubmissionModal'
import { ShareSubmissionModal } from '../dashboard/Submissions/ShareSubmissionModal'

export const CampaignPage = (): JSX.Element => {
  const { campaign, isCampaignLoading } = useCampaign()
  const navigate = useNavigate()
  const { adminUser } = useAdminAuth()
  const { submissions, isSubmissionsLoading } = useGetSubmissions({
    userId: adminUser?.id,
    campaignId: campaign?.id,
  })

  const createSubmissionDisclosure = useDisclosure()
  const shareSubmissionDisclosure = useDisclosure()
  const [shareableSubmission, setShareableSubmission] =
    useState<GetSubmissionDto | null>(null)

  const onShare = useCallback(
    (submission: GetSubmissionDto) => {
      setShareableSubmission(submission)
      shareSubmissionDisclosure.onOpen()
    },
    [shareSubmissionDisclosure],
  )

  const {
    isOpen: isOpenCollaboratorAccessModal,
    onOpen: onOpenCollaboratorAccessModal,
    onClose: onCloseCollaboratorAccessModal,
  } = useDisclosure()

  if (!isCampaignLoading && !campaign) {
    return (
      <Box>
        <AdminNotFoundComponent
          heading="Collection not found."
          subtitle="Please check back with the owner if necessary."
          buttonText="Back to all collections"
          buttonOnClick={() => navigate('/admin/collections')}
        />
      </Box>
    )
  }

  if (isCampaignLoading || !campaign || isSubmissionsLoading) return <Spinner />

  if (!submissions?.length) {
    return (
      <EmptyCollection
        campaign={campaign}
        submissions={submissions}
        onCreate={onShare}
      />
    )
  }

  return (
    <BasicAdminPage
      title={campaign.name}
      buttons={
        <ButtonGroup>
          <Button
            leftIcon={<BiUserPlus fontSize="24px" />}
            variant={'outline'}
            onClick={onOpenCollaboratorAccessModal}
          >
            Manage access
          </Button>
          <Button
            leftIcon={<BiPlus fontSize="20px" />}
            onClick={() =>
              navigate(`/admin/collections/${campaign.id}/create-submission`)
            }
          >
            Create submission link
          </Button>
        </ButtonGroup>
      }
      backButtonProps={{
        label: 'Back to all collections',
        onClick: () => navigate(`/admin/collections/`),
      }}
    >
      <Submissions onShare={onShare} />
      {submissions && (
        <CreateSubmissionModal
          {...createSubmissionDisclosure}
          campaign={campaign}
          submissions={submissions}
          onCreate={onShare}
        />
      )}
      {shareableSubmission && (
        <ShareSubmissionModal
          {...shareSubmissionDisclosure}
          submission={shareableSubmission}
        />
      )}

      <ManageCollaboratorAccessModal
        isOpen={isOpenCollaboratorAccessModal}
        onClose={onCloseCollaboratorAccessModal}
        campaignName={campaign.name}
        campaignId={campaign.id}
        campaignOwnerEmail={campaign.contactEmail}
      />
    </BasicAdminPage>
  )
}
