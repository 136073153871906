interface HighlightProps {
  text: string
  query: string
}

const escapeRegExp = (text: string) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')

export const Highlight = ({ text, query }: HighlightProps) => {
  if (!query) return <span>{text}</span>

  try {
    const escapedQuery = escapeRegExp(query)
    const regex = new RegExp(`(${escapedQuery})`, 'gi')
    const parts = text.split(regex)

    return (
      <span>
        {parts.map((part, index) =>
          part.toLowerCase() === query.toLowerCase() ? (
            <strong key={index}>{part}</strong>
          ) : (
            part
          ),
        )}
      </span>
    )
  } catch (error) {
    return <span>{text}</span>
  }
}
