import { BiChevronLeft } from 'react-icons/bi'
import { Flex, HStack, Icon, Text, VStack } from '@chakra-ui/react'

interface BackButtonProps {
  label?: string
  onClick?: () => void
}

export interface BasicAdminPageProps {
  title: string
  buttons?: React.ReactNode
  children: React.ReactNode
  backButtonProps?: BackButtonProps
}

export const BasicAdminPage = ({
  title,
  buttons,
  children,
  backButtonProps,
}: BasicAdminPageProps): JSX.Element => {
  return (
    <>
      <VStack spacing={'32px'} align="left" px={{ md: '40px', base: '20px' }}>
        {backButtonProps && (
          <HStack
            color="interaction.links.default"
            spacing={0}
            width="fit-content"
            onClick={backButtonProps.onClick}
            _hover={{ cursor: 'pointer' }}
            aria-label="back-to-collections"
          >
            <Icon as={BiChevronLeft} boxSize={5} />
            <Text textStyle="subhead-1" noOfLines={[1, 2, 3]}>
              {backButtonProps.label}
            </Text>
          </HStack>
        )}
        <Flex
          justify="space-between"
          align="center"
          alignItems={'flex-start'}
          direction={{ base: 'column', md: 'row' }}
        >
          <Text
            textStyle={'h2'}
            noOfLines={[1, 2, 3]}
            color={'grey.900'}
            fontSize={{ md: '36px', base: '28px' }}
            fontFamily={'Inter'}
            maxWidth="70%"
          >
            {title}
          </Text>
          <HStack>{buttons}</HStack>
        </Flex>
      </VStack>
      {children}
    </>
  )
}
