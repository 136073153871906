import { Button, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'

import NotFoundSvg from '~/assets/NotFound404.svg'

interface AdminNotFoundComponentProps {
  heading: string
  subtitle?: string
  description?: string
  image?: string
  buttonText?: string
  buttonOnClick?: () => void
}

export const AdminNotFoundComponent = ({
  heading,
  description,
  image,
  subtitle,
  buttonText,
  buttonOnClick,
}: AdminNotFoundComponentProps): JSX.Element => {
  return (
    <Flex pt={40} justify="center" minHeight="100vh">
      <VStack spacing={3} maxW={'90%'}>
        <Image src={image ? image : NotFoundSvg} aria-hidden h={'50%'} pb={8} />
        <Heading textAlign="center" size="lg">
          {heading}
        </Heading>
        {subtitle && <Text textAlign="center">{subtitle}</Text>}
        {description && <Text textAlign="center">{description}</Text>}
        {buttonText && buttonOnClick && (
          <Button onClick={buttonOnClick}>{buttonText}</Button>
        )}
      </VStack>
    </Flex>
  )
}
