import { useState } from 'react'
import { BiError, BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Spinner } from '@opengovsg/design-system-react'
import dayjs from 'dayjs'

import { GetDocumentDto } from '~shared/dtos'

import GreenCheckSvg from '~/assets/GreenCheck.svg'
import { AdminNotFoundComponent } from '~/components/NotFoundComponent/AdminNotFoundComponent'
import { DownloadButton } from '~/features/download'
import { fmtBytes } from '~/utils/humanReadable'

import { DownloadStatus } from '../../utils/download'
import { DeleteFileModal } from '../delete/DeleteFileModal'

import { EmptySubmissionLink } from './EmptyState/EmptySubmissionLink'
import { useSubmission, useSubmissionId } from './hooks/submissions.hooks'
import { ShareSubmissionModal } from './Submissions/ShareSubmissionModal'
import { SubmissionActionButtons } from './Submissions/SubmissionActionButtons'
import { BasicAdminPage } from './BasicAdminPage'

export const SubmissionPage = () => {
  const { submissionId } = useSubmissionId()
  const { submission, isSubmissionLoading } = useSubmission(submissionId)
  const shareDisclosure = useDisclosure()
  const navigate = useNavigate()

  if (!isSubmissionLoading && !submission)
    return (
      <Box>
        <AdminNotFoundComponent
          heading="Submission not found."
          subtitle="Please check back with the owner if necessary."
          buttonText="Back to all collections"
          buttonOnClick={() => navigate('/admin/collections')}
        />
      </Box>
    )

  if (!submission || isSubmissionLoading) return <Spinner />

  const documentSize = submission.documents.reduce(
    (total, doc) => total + doc.sizeInBytes,
    0,
  )

  if (!submission.documents.length) {
    return <EmptySubmissionLink submission={submission} />
  }

  return (
    <BasicAdminPage
      title={submission.internalId}
      buttons={
        <SubmissionActionButtons
          variant="outline"
          colorScheme="black"
          submission={submission}
          onShare={shareDisclosure.onOpen}
          onEdit={() =>
            navigate(
              `/admin/collections/${submission.campaignId}/submission/${submission.id}/edit`,
            )
          }
          documentSize={documentSize}
        />
      }
      backButtonProps={{
        label: 'Back to collection',
        onClick: () => navigate(`/admin/collections/${submission.campaignId}`),
      }}
    >
      <VStack align="stretch" divider={<Divider />} spacing={0}>
        <Header />
        {submission.documents.map((document) => (
          <DocumentRow
            key={document.id}
            campaignId={submission.campaignId}
            campaignName={submission.campaignName}
            campaignPublicKey={submission.campaignPublicKey}
            document={document}
          />
        ))}
      </VStack>
      <ShareSubmissionModal {...shareDisclosure} submission={submission} />
    </BasicAdminPage>
  )
}

const DocumentRow = ({
  document,
  campaignId,
  campaignName,
  campaignPublicKey,
}: {
  document: GetDocumentDto
  campaignId: number
  campaignName: string
  campaignPublicKey: string
}) => {
  const [progressInfo, setProgressInfo] = useState({
    progress: -1,
    info: DownloadStatus.INITIALIZING,
  })
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <HStack
      w="full"
      py={'16px'}
      align="center"
      _hover={{ bgColor: 'green.100' }}
      px="40px"
      textColor="base.content.strong"
      spacing="12px"
    >
      <VStack align="left" flex={3}>
        <Text textStyle="body-1">{document.name}</Text>
        {progressInfo.progress >= 0 &&
          progressInfo.progress <= 100 &&
          getProgressComponent(progressInfo, document.sizeInBytes)}
      </VStack>
      <Box textStyle="body-1" flex={1}>
        {fmtBytes(document.sizeInBytes)}
      </Box>
      <Box textStyle="body-1" flex={2}>
        {dayjs(document.createdAt).format('DD/MM/YYYY hh:mm a')}
      </Box>
      <Box flex={1}>
        <DownloadButton
          campaignId={campaignId}
          campaignName={campaignName}
          campaignPublicKey={campaignPublicKey}
          documents={[document]}
          isDisabled={
            document.isMalicious === null ||
            (progressInfo.progress > 0 && progressInfo.progress <= 100)
          } // disable download button, if virus scan not complete and if download in progress
          variant="clear"
          setProgressInfo={setProgressInfo}
        />
        <IconButton
          icon={<BiTrash />}
          variant={'clear'}
          textColor="interaction.critical.default"
          onClick={onOpen}
          aria-label="delete-file"
        />
        <DeleteFileModal
          isOpen={isOpen}
          onClose={onClose}
          document={document}
        />
      </Box>
    </HStack>
  )
}

const Header = () => {
  return (
    <HStack
      fontWeight="500"
      w="full"
      py="22px"
      px="40px"
      textColor={'interaction.sub.default'}
      textStyle={'subhead-2'}
    >
      <Box flex={3}>File name</Box>
      <Box flex={1}>File size</Box>
      <Box flex={2}>Uploaded at</Box>
      <Box flex={1}>Actions</Box>
    </HStack>
  )
}

const getProgressComponent = (
  progressInfo: {
    progress: number
    info: DownloadStatus
  },
  fileSizeInBytes: number,
) => {
  switch (progressInfo.info) {
    case DownloadStatus.INITIALIZING:
      return (
        <HStack>
          <Spinner fontSize={'16px'} color="primary.400" />
          <Text textStyle="caption-1" color="secondary.500">
            Initializing download...
          </Text>
        </HStack>
      )
    case DownloadStatus.DOWNLOADING:
      return (
        <HStack>
          <Spinner fontSize={'16px'} color="primary.400" />
          <Text textStyle="caption-1" color="secondary.500">
            Downloading{' '}
            {fmtBytes((progressInfo.progress * fileSizeInBytes) / 100)} of{' '}
            {fmtBytes(fileSizeInBytes)}
          </Text>
        </HStack>
      )
    case DownloadStatus.DECRYPTING:
      return (
        <HStack>
          <Spinner fontSize={'16px'} color="primary.400" />
          <Text textStyle="caption-1" color="secondary.500">
            Decrypting file...
          </Text>
        </HStack>
      )
    case DownloadStatus.FAILED:
      return (
        <HStack>
          <Box fontSize={'16px'} color="red.400">
            <BiError />
          </Box>
          <Text textStyle="caption-1" color="secondary.500">
            Download failed, refresh page to try again
          </Text>
        </HStack>
      )
    case DownloadStatus.COMPLETED:
      return (
        <HStack>
          <Box fontSize={'16px'} color="green.500">
            <Image src={GreenCheckSvg} aria-hidden boxSize="16px" />
          </Box>
          <Text textStyle="caption-1" color="secondary.500">
            Downloaded
          </Text>
        </HStack>
      )
  }
}
